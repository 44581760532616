import React, { useEffect, useState, ChangeEvent } from 'react';
import { useAuth, getAccessToken } from '../Security/Auth';
import {
    getAccreditations,
    TempAccreditation,
    confirm,
    sendQRCode,
    sendSummaryEmail,
} from './services/accreditations';

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

export const AdminPanel: React.FC = () => {
    const { isAuthenticated, user } = useAuth();
    const [accreditationList, setAccreditationList] = useState<
        TempAccreditation[]
    >([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [keyword, setKeyword] = useState<string>('');
    // console.log('isAuthenticated ' + isAuthenticated);

    useEffect(() => {
        const doGetAccreditations = async () => {};
        doGetAccreditations();
    }, []);

    const getAccreditationTypeName = (accreditationType: string): string => {
        switch (accreditationType) {
            case 'Full':
                return 'Pełna';
            case 'Vip':
                return 'Smoczy Mecenat';
            case 'Teenager':
                return 'Uczestnik 7 - 12 lat';
            case 'Program-50':
                return 'Program - 50% zniżki';
            case 'Vip-program':
                return 'Program - Smoczy Mecenat';
            case 'Program-100':
                return 'Program - 100% zniżki';
            case 'Full-guest':
                return 'Gość';
            case 'Organizer-free':
            case 'Organizer':
                return 'Organizator';
            case 'Vip-organizer':
                return 'Organizator - Smoczy Mecenat';
            case 'Staff-100':
            case 'Full-staff':
                return 'Gżdacz';
            case 'Staff-patrol':
                return 'Patrol';
            case 'Staff-medic':
                return 'Medyk';
            case 'Vip-staff':
                return 'Gżdacz - Smoczy Mecenat';
            case 'Media-100':
            case 'Full-media':
                return 'Media';
            case 'Vip-media':
                return 'Media - Smoczy Mecenat';
            case 'Full I-pool':
                return 'Pełny - I pula';
            case 'Full II-pool':
                return 'Pełny - II pula';
            case 'Full Regular':
                return 'Pełny - regularna pula';
            default:
                return '';
        }
    };

    const onValueChange = async (e: ChangeEvent<HTMLInputElement>) => {
        const accessToken = await getAccessToken();

        setIsLoading(true);
        const accreditations = await getAccreditations(
            e.target.value,
            accessToken,
        );
        setIsLoading(false);
        setKeyword(e.target.value);
        setAccreditationList(accreditations);
    };

    const expandTile = (id: number) => {
        const index = accreditationList.findIndex((a) => a.id === id);

        const accreditation = accreditationList[index];
        accreditation.isExpanded = accreditation.isExpanded ? false : true;

        setAccreditationList([
            ...accreditationList.slice(0, index),
            accreditation,
            ...accreditationList.slice(index + 1, accreditationList.length),
        ]);
    };

    const onConfirmClick = async (id: number) => {
        setIsLoading(true);
        const accessToken = await getAccessToken();

        await confirm(id, accessToken);

        const accreditations = await getAccreditations(keyword, accessToken);
        setIsLoading(false);
        setAccreditationList(accreditations);
    };

    const onQrCodeSendClick = async (id: number) => {
        setIsLoading(true);
        const accessToken = await getAccessToken();

        await sendQRCode(id, accessToken);

        const accreditations = await getAccreditations(keyword, accessToken);
        setIsLoading(false);
        setAccreditationList(accreditations);
    };

    const onSendSummaryEmailClick = async (id: number) => {
        setIsLoading(true);
        const accessToken = await getAccessToken();

        await sendSummaryEmail(id, accessToken);

        const accreditations = await getAccreditations(keyword, accessToken);
        setIsLoading(false);
        setAccreditationList(accreditations);
    };

    const getFullName = (firstName: string, surName: string): string => {
        return `${firstName} ${surName}`;
    };

    return (
        <div>
            <div>
                <input
                    css={css`
                        line-height: 45px;
                        box-shadow: rgb(0 0 0 / 8%) 0px 1px 3px 0px inset !important;
                        color: #666666;
                        background: #fafafa;
                        border-radius: 5px;
                        padding: 0px 20px;
                        transition: all 0.4s ease 0s;
                        font-size: 14px;
                        border-style: none;
                        outline: none;
                        margin-right: 5px;
                    `}
                    type="text"
                    autoComplete="false"
                    onChange={onValueChange}
                    placeholder="Szukaj"
                />
                {isLoading && <span>Loading...</span>}
            </div>
            <div>
                <ul
                    css={css`
                        list-style: none;
                    `}
                >
                    {accreditationList.map((accreditation) => (
                        <li
                            key={accreditation.id}
                            onClick={() => expandTile(accreditation.id)}
                        >
                            <div
                                css={css`
                                    display: flex;
                                    text-align: center;
                                    align-items: center;
                                    height: auto;
                                    max-height: ${accreditation.isExpanded
                                        ? `1000px`
                                        : `70px`};
                                    &:hover {
                                        background-color: #f4c2c2;
                                    }
                                    padding-top: 10px;
                                    padding-bottom: 10px;
                                    transition: max-height 4s ease-out;
                                `}
                            >
                                {!accreditation.isExpanded && (
                                    <React.Fragment>
                                        <span
                                            css={css`
                                                flex: 1 1 0px;
                                            `}
                                        >
                                            {accreditation.fullName}
                                        </span>
                                        <span
                                            css={css`
                                                flex: 1 1 0px;
                                            `}
                                        >
                                            {accreditation.email}
                                        </span>
                                        <span
                                            css={css`
                                                flex: 1 1 0px;
                                            `}
                                        >
                                            {getAccreditationTypeName(
                                                accreditation.accreditationType,
                                            )}
                                        </span>
                                        <span
                                            css={css`
                                                flex: 1 1 0px;
                                            `}
                                        >
                                            {accreditation.price}
                                        </span>
                                        <span
                                            css={css`
                                                flex: 1 1 0px;
                                            `}
                                        >
                                            {accreditation.alreadyPaid
                                                ? 'Opłacona'
                                                : 'Nieopłacona'}
                                        </span>
                                        <span
                                            css={css`
                                                flex: 1 1 0px;
                                            `}
                                        >
                                            {accreditation.qrCodeSent
                                                ? 'Wysłany'
                                                : 'Niewysłany'}
                                        </span>
                                        <span>
                                            <button
                                                onClick={() =>
                                                    onConfirmClick(
                                                        accreditation.id,
                                                    )
                                                }
                                                css={css`
                                                    border-color: #326e99;
                                                    background-color: #3f8abf;
                                                    border: 0;
                                                    color: #ffffff;
                                                    border-radius: 6px;
                                                    cursor: pointer;
                                                    display: inline-block;
                                                    text-decoration: none;
                                                    transition: all 200ms
                                                        ease-in-out 0s;
                                                    padding: 7px 25px;
                                                `}
                                                type="button"
                                            >
                                                Potwierdź
                                            </button>
                                            <button
                                                onClick={() =>
                                                    onSendSummaryEmailClick(
                                                        accreditation.id,
                                                    )
                                                }
                                                css={css`
                                                    border-color: #326e99;
                                                    background-color: #3f8abf;
                                                    border: 0;
                                                    color: #ffffff;
                                                    border-radius: 6px;
                                                    cursor: pointer;
                                                    display: inline-block;
                                                    text-decoration: none;
                                                    transition: all 200ms
                                                        ease-in-out 0s;
                                                    padding: 7px 25px;
                                                    margin-left: 2px;
                                                `}
                                                type="button"
                                            >
                                                Bilety
                                            </button>
                                        </span>
                                    </React.Fragment>
                                )}
                                {accreditation.isExpanded && (
                                    <div
                                        css={css`
                                            padding: 10px 40px 10px 40px;
                                        `}
                                    >
                                        <div
                                            css={css`
                                                display: flex;
                                                width: 100%;
                                                justify-content: space-between;
                                            `}
                                        >
                                            <div>
                                                <label
                                                    css={css`
                                                        display: block;
                                                        font-size: 12px;
                                                        text-align: left;
                                                    `}
                                                >
                                                    Uczestnik
                                                </label>
                                                <span>
                                                    {accreditation.fullName}
                                                </span>
                                            </div>
                                            <div>
                                                <label
                                                    css={css`
                                                        display: block;
                                                        font-size: 12px;
                                                        text-align: left;
                                                    `}
                                                >
                                                    Typ Akredytacji
                                                </label>
                                                <span>
                                                    {
                                                        accreditation.accreditationType
                                                    }
                                                </span>
                                            </div>
                                            <div>
                                                <label
                                                    css={css`
                                                        display: block;
                                                        font-size: 12px;
                                                        text-align: left;
                                                    `}
                                                >
                                                    Data rejestracji
                                                </label>
                                                <span>
                                                    {
                                                        accreditation.registrationDate
                                                    }
                                                </span>
                                            </div>
                                            <div>
                                                <label
                                                    css={css`
                                                        display: block;
                                                        font-size: 12px;
                                                        text-align: left;
                                                    `}
                                                >
                                                    Status
                                                </label>
                                                <span>
                                                    <strong>Opłacona</strong>
                                                </span>
                                            </div>
                                        </div>
                                        <div>Gadżety</div>
                                    </div>
                                )}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};
