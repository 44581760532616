import { MerchOptions } from '../../signUp/components/SignUpForm';
import { MapMerchToRest } from '../../signUp/mappers/submit';

//TODO JK: Przenieść w odpowiednie miejsce

const postData = async (url = '', data: any) => {
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data), // body data type must match "Content-Type" header
    });

    if (response.status === 400) {
        return Promise.reject();
    }

    if (url.includes('emailCheck')) {
        return Promise.resolve();
    }

    return await response.json();

    //return Promise.resolve();
    // return await response.json(); // parses JSON response into native JavaScript objects
};

//TODO JK: Mapper;

export const submit = async (data: any) => {
    Object.keys(data).forEach((key: any) => {
        if (data[key] === 'true') {
            data[key] = true;
        }

        if (data[key] === 'false') {
            data[key] = false;
        }

        if (key === 'Merch') {
            data['Merch'] = MapMerchToRest(data['Merch']);
        }
    });

    await postData(
        //'https://backend.nakonwent.pl/accreditation',
        'https://localhost:44357/accreditation',
        data,
    ).then((data: any) => {
        window.location.href = `https://sandbox.przelewy24.pl/trnRequest/${data.transactionToken}`;
    });
};

export const EmailCheck = async (email: string) => {
    await postData('https://backend.nakonwent.pl/emailCheck', email).then(() =>
        Promise.resolve(true),
    );
};
