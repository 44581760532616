/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

const SignUpPrivacyPolicy: React.FC = () => {
    return (
        <div>
            <div
                css={css`
                    font-size: 20px;
                    text-align: center;
                    margin-top: 40px;
                    margin-bottom: 40px;
                `}
            >
                Polityka prywatności
            </div>
            <div
                css={css`
                    font-size: 16px;
                    margin-left: 30px;
                    margin-right: 30px;
                    line-height: 2;
                `}
            >
                <p
                    css={css`
                        font-size: 18px;
                    `}
                >
                    Postanowienia ogólne:
                </p>
                1. Polityka prywatności określa, jak zbierane, przetwarzane i
                przechowywane są dane osobowe Uczestników festiwalu niezbędne do
                świadczenia usług drogą elektroniczną za pośrednictwem strony
                https://injuregrindattack.pl, zwanej dalej “Serwisem”.
                <br />
                2. Serwis zbiera wyłącznie dane osobowe niezbędne do świadczenia
                usług w nim oferowanych.
                <br />
                3. Dane osobowe zbierane za pośrednictwem Serwisu są
                przetwarzane zgodnie z Rozporządzeniem Parlamentu Europejskiego
                i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie
                ochrony osób fizycznych w związku z przetwarzaniem danych
                osobowych i w sprawie swobodnego przepływu takich danych oraz
                uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie
                danych, dalej “RODO”) oraz ustawą o ochronie danych osobowych z
                dnia 10 maja 2018 r. <br />
                <p
                    css={css`
                        font-size: 18px;
                    `}
                >
                    Administrator danych:
                </p>
                1. Administratorem danych osobowych jest spółka Injure Grind sp.
                z o.o. non profit, ul. Równoległa 21, 42-400 Zawiercie, NIP:
                6492332678, REGON: 528266440, zwana dalej “Administratorem”.
                <p
                    css={css`
                        font-size: 18px;
                    `}
                >
                    Cel zbierania danych osobowych:
                </p>
                1. Dane osobowe wykorzystywane są do świadczenia usług przez
                Serwis: sprzedaży towaru oraz biletów na festiwal Injure Grind
                Attack.
                <p
                    css={css`
                        font-size: 18px;
                    `}
                >
                    Rodzaj przetwarzanych danych osobowych:
                </p>
                1. Administrator może przetwarzać dane osobowe Użytkowników,
                niezbędne do świadczenia usług przez Serwis: imię, nazwisko,
                adres poczty elektronicznej. uprawnień do przebywania na
                festiwalu.
                <p
                    css={css`
                        font-size: 18px;
                    `}
                >
                    Okres przetwarzania danych osobowych:
                </p>
                1. Dane osobowe Użytkowników będą przetwarzane do momentu
                przedawnienia roszczeń z umowy. filmach lub zdjęciach, a
                następnie rozpowszechniony w celach promocyjnych, na co wyraża
                on zgodę.
                <p
                    css={css`
                        font-size: 18px;
                    `}
                >
                    Udostępnianie danych osobowych:
                </p>
                1. Dane osobowe Użytkowników mogą być przekazywane firmie PayPro
                w celach związanych ze świadczeniem usług płatniczych
                udostępnionych przez Serwis, stosownie do art. 13 ust. 1 lit. e
                RODO.
                <br />
                2. Dane osobowe Użytkowników nie będą przekazywane poza teren
                Europejskiego Obszaru Gospodarczego (EOG).
                <p
                    css={css`
                        font-size: 18px;
                    `}
                >
                    Prawa Użytkowników:
                </p>
                1. Użytkownik Serwisu ma prawo do: dostępu do treści swoich
                danych osobowych, ich sprostowania, usunięcia, ograniczenia
                przetwarzania, przenoszenia, wniesienia sprzeciwu wobec
                przetwarzania, cofnięcia zgody w każdej chwili (co nie ma wpływu
                na zgodność z prawem przetwarzania dokonanego w oparciu o zgodę
                przed jej cofnięciem).
                <br />
                2. Zgłoszenie o wystąpieniu przez Użytkownika z uprawnieniem
                wynikającym z wymienionych praw należy przesłać na adres
                tickets@injuregrindattack.pl.
                <br />
                3. Administrator spełnia lub odmawia spełnienia żądania
                niezwłocznie – maksymalnie w ciągu miesiąca od jego otrzymania.
                <p
                    css={css`
                        font-size: 18px;
                    `}
                >
                    Postanowienia końcowe:
                </p>
                1. Administrator ma prawo do wprowadzenia zmian w Polityce
                prywatności, przy czym prawa Użytkowników nie zostaną
                ograniczone.
                <br />
                2. Informacja o wprowadzonych zmianach pojawi się w formie
                komunikatu dostępnego w Serwisie.
                <br />
                3. W sprawach nieuregulowanych w niniejszej Polityce prywatności
                obowiązują przepisy RODO i przepisy prawa polskiego.
                <br />
            </div>
        </div>
    );
};

export default SignUpPrivacyPolicy;
